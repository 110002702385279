<template>
  <base-card class="search-result-section ma-10">
    <v-card-title class="justify-center pb-0">
      <div class="px-4 text-center font-weight-bold text-h6 orange--text text--darken-4 search-title">
        {{ $t("price.searchResult") }}
      </div>
   </v-card-title>

   <v-card-text>
      <v-data-table
        :headers="headers"
        :items="productList"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md ma-3"
      >
        <template v-slot:item.network="{item}">
          <span class="font-weight-bold">{{ item.network }}</span>
        </template>
      </v-data-table>
   </v-card-text>
  </base-card>
</template>

<script>
export default {
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [{
        text: this.$t('price.network'),
        value: 'network',
      }, {
        text: this.$t('price.type'),
        value: 'type',
      }, {
        text: this.$t('price.speed'),
        value: 'speed',
      }, {
        text: this.$t('price.startCost'),
        value: 'start',
      }, {
        text: this.$t('price.monthlyCost'),
        value: 'price',
      }, {
        text: this.$t('price.term'),
        value: 'term',
      }, {
        text: this.$t('price.nodeType'),
        value: 'nodeType',
      }],
    }
  },
}
</script>

<style lang="scss">
.v-card.search-result-section {
  padding: 30px 0;
  width: 90%;

  .search-title {
    border-bottom: 1px dotted;
  }

  .section-title {
    text-align: center;
    width: fit-content;
    margin: 0 auto 10px;
    padding: 0 20px;
    font-size: 16px;
    color: #ab380c;
  }
}
</style>
