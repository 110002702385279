<template>
  <v-app class="public-search-page">
    <div class="mt-4">
      <check-availability
        @set-search-result="setSearchResult"
        @set-search-type="setSearchType"
        @set-search-address="setSearchAddress"
      />

      <available-accesses
        v-if="isSearched && availableAccesses.length > 0"
        :available-accesses="availableAccesses"
        @set-products-services="setProductsServices"
      />

      <available-stokab-points
        v-if="isSearched && stokabPoints.length > 0"
        :stokab-points="stokabPoints"
        @set-stokab-products="setStokabProducts"
      />

      <products-table
        v-if="availableProductList.length > 0 && searchType === 'commercial'"
        :product-list="availableProductList"
        :available-services="availableServices"
      />

      <products-card
        v-if="availableProductList.length > 0 && searchType === 'residential'"
        :address="address"
        :product-list="availableProductList"
        :available-services="availableServices"
      />
    </div>
  </v-app>
</template>

<script>
  import { showWarningDialog } from '@/utils/swalDialog'

  import CheckAvailability from '../components/CheckAvailability.vue'
  import ProductsTable from '../components/ProductsTable.vue'
  import ProductsCard from '../components/ProductsCard.vue'
  import AvailableAccesses from '../components/AvailableAccesses.vue'
  import AvailableStokabPoints from '../components/AvailableStokabPoints.vue'

  export default {
    components: {
      CheckAvailability,
      ProductsTable,
      ProductsCard,
      AvailableAccesses,
      AvailableStokabPoints,
    },

    data () {
      return {
        isSearched: false,
        searchType: '',
        availableProductList: [],
        productList: [],
        priceInfo: null,
        availableAccesses: [],
        address: {
          street: '',
          locality: '',
          postalCode: '',
          streetNumber: '',
          streetLetter: '',
        },
        availableServices: [],
        stokabPoints: [],
      }
    },

    mounted () {
      const lang = this.$route.params.language || 'se'
      this.$i18n.locale = lang
      localStorage.setItem('language', lang)
      localStorage.removeItem('access_token')
      localStorage.removeItem('role')
    },

    beforeDestroy () {
      localStorage.removeItem('language')
    },

    methods: {
      setSearchResult (result) {
        this.isSearched = false
        this.productList = []
        this.availableProductList = []
        this.availableAccesses = []

        if (!result) {
          return
        }

        if (result.stokab) {
          this.isSearched = true
          this.stokabPoints = result.stokab.availablePoints || []
        }

        if (result.sollentuna) {
          this.isSearched = true

          const availableSollentunaAccesses = (result.sollentuna?.rows || []).map(item => ({
            ...item,
            network: 'sollentuna',
          }))
          this.availableAccesses.push(...availableSollentunaAccesses)
        }

        if (result.openinfra) {
          this.isSearched = true

          const availableOpeninfraAccesses = (result.openinfra?.rows || []).map(item => ({
            ...item,
            network: 'openinfra',
          }))
          this.availableAccesses.push(...availableOpeninfraAccesses)
        }

        if (result.openuniverse) {
          this.isSearched = true

          const availableOpenuniverseAccesses = (result.openuniverse?.rows || []).map(item => ({
            ...item,
            network: 'openuniverse',
          }))
          this.availableAccesses.push(...availableOpenuniverseAccesses)
        }

        if (result.ipOnly) {
          this.isSearched = true

          const availableIpOnlyAccesses = (result.ipOnly?.rows || []).map(item => ({
            ...item,
            network: 'ipOnly',
          }))
          this.availableAccesses.push(...availableIpOnlyAccesses)
        }

        if (result.zitius) {
          this.isSearched = true

          const availableZitiusAccesses = (result.zitius?.rows || []).map(item => ({
            ...item,
            network: 'zitius',
          }))
          this.availableAccesses.push(...availableZitiusAccesses)
        }

        if (!this.isSearched) {
          showWarningDialog(this.$t('address.cantDeliveryProduct'))
        }
      },

      setSearchType (type) {
        this.searchType = type
      },

      setSearchAddress (addres) {
        this.address = addres
      },

      setProductsServices (data) {
        this.availableServices = data.services
        this.availableProductList = data.products
      },

      setStokabProducts (productList) {
        this.availableProductList = productList
      },
    },
  }
</script>

<style lang="scss">
  .public-search-page {
    background: #f2f3f8 !important;
  }
</style>
